<template>
  <div>
    <v-data-table :loading="loadingTable" disable-pagination disable-sort hide-default-footer :headers="headers"
      :items="categories" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Reservas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportExcel()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
                </v-list-item-title>

              </v-list-item>
              <v-list-item @click="sendExportPdf()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFilePdfOutline }}</v-icon> Exportar PDF
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                @click="sendExportPdf()">
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="newTab" target="_blank" v-bind="attrs" v-on="on">
                <v-btn class="ml-2" icon large outlined color="primary">
                  <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
                </v-btn>
              </a>
            </template>
            <span>Nova Aba</span>
          </v-tooltip>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" rounded outlined large
              color="primary"><v-icon>{{ icons.mdiFilter
                }}</v-icon>Filtros</v-btn>
          </v-badge>
          <v-spacer></v-spacer>
          <span v-if="filter.checkin_date_start && filter.checkin_date_end">Período Checkin: de <b
              style="color:black">{{ formatDate(filter.checkin_date_start) }}</b> até <b
              style="color:black">{{ formatDate(filter.checkin_date_end) }}</b> </span>

        </v-toolbar>
      </template>
      <template v-slot:item.guests="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span dark v-bind="attrs" v-on="on">
              {{ firstGuest(item) }}
            </span>
          </template>
          <span>{{ allGuests(item) }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip color="info" v-if="item.status == 'confirmed'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="success" v-if="item.status == 'checkin'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="error" v-if="item.status == 'checkout'">
          {{ reserved(item) }}
        </v-chip>
        <v-chip color="secondary" v-if="item.status == 'canceled'">
          {{ reserved(item) }}
        </v-chip>
      </template>
      <template v-slot:item.room="{ item }">
        {{ qtdRoom(item) }}
      </template>
      <template v-slot:item.check_in="{ item }">
        {{ formatDate(item.check_in) }}
      </template>
      <template v-slot:item.check_out="{ item }">
        {{ formatDate(item.check_out) }}
      </template>
      <template v-slot:item.diary="{ item }">
        {{ formatMoney(item.extraordinary_tariff[0].value) }}
      </template>
      <template v-slot:item.room_description="{ item }">
        {{ roomDescription(item) }}
      </template>


      <template v-slot:foot>
        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DE RESERVAS:
            <b>{{ countReserves() }}</b></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>


        </tr>
        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DE
            APARTAMENTOS:<b> {{ qtdRoomTotal() }}</b></td>
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL HÓSPEDES:<b>
              {{ qtdGuestTotal() }}</b></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
        </tr>

        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DIÁRIAS (R$):
            <b>{{ formatMoney(diaryTotal()) }}</b></td>
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">DIÁRIA MEDIA (R$):
            <b>{{ formatMoney(diaryMedia()) }}</b></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
        </tr>
      </template>


      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <!-- DIALOG FILTRO -->
    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="filter.checkin_date_start" outlined type="date"
                label="Data Checkin Inicial"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="filter.checkin_date_end" outlined type="date"
                label="Data Checkin Final"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-select class="mr-3" v-model="filter.status" clearable outlined label="Status"
                :items="itemsStatus"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
  </div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiFileExcelOutline,
  mdiFilePdfOutline,
  mdiFilter,
  mdiOpenInNew,
  mdiPencilOutline,
  mdiPlus,
  mdiPrinter,
  mdiTools,
} from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    filter: {},
    dialogFilter: false,
    categoryItems: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'EMPRESA',
        value: 'company.fantasy_name',
      },
      {
        text: 'HÓSPEDE',
        value: 'guests',
      },
      {
        text: 'CATEGORIA',
        value: 'room_category.description',
      },
      {
        text: 'QTD. QUARTOS',
        value: 'room',
        align: 'center',
      },
      {
        text: 'CHECKIN',
        value: 'check_in',
      },
      {
        text: 'CHECKOUT',
        value: 'check_out',
      },
      {
        text: 'DIÁRIA',
        value: 'diary',
      },
      {
        text: 'QUARTO',
        value: 'room_description',
        align: 'center',
      },
      {
        text: 'STATUS',
        align: 'center',
        value: 'status',
      },
    ],
    categories: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    itemsStatus: [
      { text: 'Checkin', value: 'checkin' },
      { text: 'Checkout', value: 'checkout' },
      { text: 'Confirmado', value: 'confirmed' },
    ],
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiFilePdfOutline,
      mdiPrinter,
    },
  }),

  computed: {
    newTab() {
      let url_atual = window.location.href
      url_atual = url_atual.replace('#', '')
      return url_atual + '_blank'
    },
    bagFilter() {
      if (this.filter.status == null) {
        delete this.filter.status
      }
      return Object.keys(this.filter).length
    },
  },
  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('reserves_today', ['getAll', 'exportExcel', 'exportPdf']),
    initialize() {
      this.loadingTable = true
      this.getAll(this.filter)
        .then(response => {
          this.categories = response.data
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    clearFilter() {
      this.filter = {}
    },
    sendExportExcel() {
      this.exportExcel(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },
    sendExportPdf() {
      this.exportPdf(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },
    roomDescription(item) {
      let rooms_descrition = []
      item.room.forEach(room => {
        rooms_descrition.push(room.description)
      })
      return rooms_descrition.join()
    },
    qtdRoom(item) {
      if (item.room.length > 0) {
        return item.room.length
      } else if (item.qtd_room) {
        return item.qtd_room
      } else {
        return 0
      }
    },

    qtdRoomTotal() {
      return this.categories.reduce((acc, item) => {
        return acc + parseFloat(this.qtdRoom(item))
      }, 0)
    },

    qtdGuestTotal() {
      return this.categories.reduce((acc, item) => {
        return acc + parseFloat(item.guest.length + item.guest_unknown.length)
      }, 0)
    },
    diaryTotal() {
      return this.categories.reduce((acc, item) => {
        return acc + parseFloat(item.extraordinary_tariff[0].value)
      }, 0)
    },
    diaryMedia() {
      const diaryTotal = this.diaryTotal()

      if (diaryTotal > 0) {
        return diaryTotal / this.qtdRoomTotal()
      }

      return 0
    },
    firstGuest(item) {
      if (item.guest.length > 0) {
        return item.guest[0].name
      } else if (item.guest_unknown.length > 0) {
        return item.guest_unknown[0].name
      }
      return ''
    },
    allGuests(item) {
      let guests = []
      item.guest.forEach(guest => {
        guests.push(guest.name)
      })

      item.guest_unknown.forEach(guest => {
        guests.push(guest.name)
      })

      return guests.join()
    },
    reserved(reserve) {
      switch (reserve.status) {
        case 'confirmed':
          return 'Confirmado'
        case 'checkin':
          return 'Checkin'
        case 'checkout':
          return 'Checkout'
        case 'canceled':
          return 'Canceled'
      }
    },

    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      }
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    countReserves() {
      return this.categories.length
    },
  },
}
</script>
